@import '_variables';

@font-face {
  font-family: 'BC-Falster-Grotesk-Regular';
  src: url('/fonts/BC-Falster-Grotesk-Regular-5eff2d62beb194306abca5585932f2b5.woff2')
      format('woff2'),
    url('/fonts/BC-Falster-Grotesk-Regular-5eff2d62beb194306abca5585932f2b5.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: normal;
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  background: var(--ui-color);
  margin: 0;
  padding: 0;
  font-family: 'BC-Falster-Grotesk-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .safari & {
    -webkit-text-size-adjust: none;
  }
}

button,
input {
  font-family: 'BC-Falster-Grotesk-Regular';
}

html {
  height: 100%;
  background: black;

  // .ios.landscape {
  //   // height: calc(100% + 1px);
  // }
}
body {
  height: 100%;
  position: relative;
}

br.responsive {
  .mobile.landscape & {
    content: ' ';
    &:after {
      content: ' ';
    }
  }
}

br.on-tablet {
  content: ' ';
  &:after {
    content: ' ';
  }

  @include tablet() {
    content: none;
    &::after {
      content: none;
    }
  }
}

br.off-tablet {
  @include tablet() {
    content: ' ';
    &:after {
      content: ' ';
    }
  }
}

.desktop-only {
  display: none;
  @include desktop() {
    display: block;
  }
}

.not-desktop {
  display: block;
  @include desktop() {
    display: none;
  }
}
