@import '../../_typography';
@import '../../_variables';

.WelcomeScreen {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateZ(0);
  perspective: 1000;

  .Enter {
    @extend .evenly-like;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    color: var(--ui-color);
    padding-bottom: 4em;

    button {
      @extend .enter-button-type;
    }

    .title {
      padding-bottom: 3em;
    }

    h1 {
      @extend .welcome-type;
      color: var(--ui-color);
      margin-top: -0.1em;

      .mobile.landscape & {
        margin-bottom: -0.5em;
      }
    }

    .subtitle {
      @extend .subtitle-type;
      margin-top: 0.3em;
    }
  }

  &.new-version {
    .Enter {
      padding-bottom: 0;
    }
  }

  .mobile.landscape & {
    .Enter {
      padding-bottom: 0;

      h1 {
        margin: 0;
      }

      .title {
        margin-top: -1em;
        padding-bottom: 0;
      }
    }
  }
}
