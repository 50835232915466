@import '../../_variables';

.App {
  text-align: center;

  .titles-overlay {
    pointer-events: none !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zTextAnimations;
    backface-visibility: hidden;
    perspective: 1000;
    transform: translateZ(0);

    > div {
      box-sizing: border-box;
      position: absolute;
    }
  }

  .ui {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zUi;
    pointer-events: none;

    > * {
      pointer-events: all;
    }
  }

  &.installation {
    cursor: none;
  }
}
