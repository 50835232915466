@import '../../_typography';
@import '../../_variables';

.Assignment {
  .Overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .instruction,
  .confirmation-text {
    @extend .instruction-type;
    color: var(--ui-color);
    position: absolute;
    left: 0;
    right: 0;
    padding: 14px 35px 50px 35px;
    @include scrimGradient(rgba(0, 0, 0, 0.2), 'to bottom');
    text-align: center;
    pointer-events: none;
  }

  .confirmation-buttons {
    position: absolute;
    right: 16px;
    height: 155px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-65px);

    &:after {
      content: '';
      position: absolute;
      left: 2px;
      right: 2px;
      transform: translateY(73px);
      border: 1px solid var(--ui-color);
    }

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: -10px;
      left: -15px;
      right: -100px;
      bottom: -10px;
      background: rgba(0, 0, 0, 0.275);
      filter: blur(40px);
    }
  }

  .confirmation-button {
    @extend .confirmation-type;
    background: none;
    border: 0;
    color: var(--ui-color);
  }

  h1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include small() {
    .instruction,
    .confirmation-text {
      padding-top: 11px;
    }

    .confirmation-buttons {
      height: 130px;
      &:after {
        transform: translateY(62px);
      }
    }
  }

  @include tablet() {
    .instruction,
    .confirmation-text {
      padding-top: 20px;
    }

    .confirmation-buttons {
      right: 36px;
      transform: translateY(-85px);
    }
  }
}
