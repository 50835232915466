@import '../../_variables';

.Button {
  background: none;
  border: 0;
  line-height: 0;

  circle,
  ellipse {
    fill: var(--ui-color);
  }

  svg {
    pointer-events: none;
  }
}

.NavButtons {
  .Button {
    z-index: $zUi + 1;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  .AboutButton {
    position: absolute;
    top: 10px;
    right: 7px;
  }

  .ShareRoundButton {
    position: absolute;
    top: 61px;
    right: 7px;
  }

  @include small() {
    .Button svg {
      width: 30px;
      // height: auto;
    }
    .ShareRoundButton {
      top: 55px;
    }
  }

  @include tablet() {
    .Button svg {
      width: 42px;
      height: auto;
    }
    .Button {
      right: 16px;
    }
    .AboutButton {
      top: 18px;
    }

    .ShareRoundButton {
      top: 80px;
    }
  }
}

.CloseButton {
  position: absolute;
  z-index: $zOverlay + 1;
  padding: 8px 8px 35px 35px;
  top: 0;
  right: 0;

  svg {
    width: 18px;
    height: auto;
  }

  path {
    stroke: var(--ui-color);
  }

  @include small() {
    top: 0;
    padding-top: 8px;
    svg {
      width: 12px;
      height: auto;
    }
  }

  @include tablet() {
    top: 3px;
    right: 3px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.RecordButton {
  border-radius: 50%;
  width: 72px;
  height: 72px;
  background: none;
  border: 5px solid var(--ui-color);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.94;

  .innerCircle {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: var(--ui-color);
  }

  @include small() {
    width: 60px;
    height: 60px;
    border: 4px solid var(--ui-color);

    .innerCircle {
      width: 48px;
      height: 48px;
    }
  }
}

.SwitchCameraButton {
  path {
    fill: var(--ui-color);
  }

  @include small() {
    svg {
      height: 20px;
      width: auto;
    }
  }
}

.Button.LabelButton {
  font-size: 1em;
  letter-spacing: (25em / 1000);
  border: 0;
  border-bottom: 0.08em solid var(--ui-color);
  line-height: 1.2;
  color: var(--ui-color);
  text-transform: uppercase;
  background: none;
  padding: 0;
  display: inline-block;
}

.Button.ShareButton {
  svg {
    height: 32px;
    width: auto;
  }
}

@include small() {
  .ShareButton {
    svg {
      height: 22px;
      width: auto;
    }
  }
}

.MuteButton {
  .color-fill,
  .color-stroke {
    fill: black;
  }
}
