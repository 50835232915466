@import '../../_typography';

.BottomBar {
  width: 100%;
  height: 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  pointer-events: all !important;

  &.has-background {
    border-top: 2px solid black;
    background: var(--ui-color);
  }

  &.has-gradient {
    color: var(--ui-color);
    background: rgba(0, 0, 0, 0.35);

    .text {
      padding: 0;
    }
  }

  &.no-bg {
    padding-bottom: 13px;

    .text {
      @extend .bottom-box-type.no-bg;
    }
  }

  &.has-icon {
    .text {
      text-align: left;
      padding-right: 0;
    }
  }

  .text {
    @extend .bottom-box-type;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 2px;
    flex: 1;
    text-align: center;
  }

  .ShareButton {
    padding-right: 25px;
  }

  .mobile.landscape & {
    height: 54px;

    &.no-bg {
      padding-bottom: 5px;
    }
  }

  @include small() {
    height: 68px;

    &.has-icon {
      .text {
        padding-right: 0;
      }
    }

    .mobile.landscape & {
      height: 42px;
    }
  }

  @include tablet() {
    height: 67px;

    .text {
      padding-top: 4px;

      br {
        content: ' ';
        &:after {
          content: ' ';
        }
      }
    }
  }
}
