@import '../../_typography';

.ShareBar {
  flex-direction: column;
  @extend .bottom-box-type;

  .LabelButton {
    border: 0;
    margin-top: 3px;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

  &.has-background .LabelButton {
    color: black;
  }
}
