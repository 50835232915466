@charset "UTF-8";
/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
.evenly-like {
  display: flex;
  justify-content: space-between; }
  .evenly-like:before, .evenly-like:after {
    content: '';
    display: block; }

.loading-type {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.01em; }
  .mobile.landscape .loading-type {
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.02em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .loading-type {
      font-size: 42px;
      line-height: 55px; }
      .mobile.landscape .loading-type {
        font-size: 42px;
        line-height: 55px; } }
  @media (min-width: 750px) {
    .loading-type {
      font-size: 80px;
      line-height: 90px;
      letter-spacing: -0.02em; } }

.subtitle-type {
  font-size: 22px;
  line-height: 30px; }

.welcome-type {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.02em; }
  .welcome-type.long-title {
    font-size: 51px;
    line-height: 54px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .welcome-type {
      font-size: 50px;
      line-height: 55px; } }
  @media (min-width: 750px) {
    .welcome-type {
      font-size: 90px;
      line-height: 95px; } }

.enter-button-type {
  font-size: 55px;
  line-height: 65px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  border-bottom: 3px solid var(--ui-color); }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .enter-button-type {
      font-size: 47px;
      line-height: 52px; } }
  @media (min-width: 750px) {
    .enter-button-type {
      font-size: 80px;
      line-height: 90px;
      letter-spacing: 0.025em;
      border-bottom: 5px solid var(--ui-color); } }

.title-seq-type {
  font-size: 63px;
  line-height: 65px;
  letter-spacing: -0.02em; }
  .mobile.landscape .title-seq-type {
    font-size: 77px;
    line-height: 76px;
    letter-spacing: -0.01em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .title-seq-type {
      font-size: 57px;
      line-height: 60px; }
      .mobile.landscape .title-seq-type {
        font-size: 57px;
        line-height: 60px; } }
  @media (min-width: 750px) {
    .title-seq-type {
      font-size: 108px;
      line-height: 112px;
      letter-spacing: -0.015em; } }

.bottom-box-type, .bottom-box-type.no-bg {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.005em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .bottom-box-type, .bottom-box-type.no-bg {
      font-size: 18px;
      line-height: 22px; } }
  @media (min-width: 750px) {
    .bottom-box-type, .bottom-box-type.no-bg {
      font-size: 29px;
      line-height: 27px;
      letter-spacing: 0; } }

.no-bg.bottom-box-type {
  line-height: 30px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .no-bg.bottom-box-type {
      line-height: 26px; } }

.hint-type, .Hint {
  font-size: 28px; }
  .mobile.landscape .hint-type, .mobile.landscape .Hint {
    font-size: 30px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .hint-type, .Hint {
      font-size: 25px;
      line-height: 33px; } }
  @media (min-width: 750px) {
    .hint-type, .Hint {
      font-size: 33px; } }

.instruction-type {
  font-size: 27px;
  line-height: 32px;
  letter-spacing: 0.015em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .instruction-type {
      font-size: 24px;
      line-height: 29px; } }
  @media (min-width: 750px) {
    .instruction-type {
      font-size: 30px;
      line-height: 34px; } }

.quote-type {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .quote-type {
      font-size: 16px;
      line-height: 20px; } }
  @media (min-width: 750px) {
    .quote-type {
      font-size: 20px;
      line-height: 26px; } }

.confirmation-type {
  font-size: 29px;
  letter-spacing: 0.03em;
  text-transform: uppercase; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .confirmation-type {
      font-size: 25px; } }
  @media (min-width: 750px) {
    .confirmation-type {
      font-size: 31px;
      line-height: 40px; } }

.language-type {
  font-size: 23px;
  line-height: 1em;
  padding-bottom: 2px;
  text-transform: uppercase; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .language-type {
      font-size: 18px;
      line-height: 22px; } }
  @media (min-width: 750px) {
    .language-type {
      font-size: 29px;
      padding-bottom: 1px;
      line-height: 0.9em; } }
  @media (min-width: 1024px) {
    .language-type {
      font-size: 22px;
      padding-bottom: 0;
      padding-top: 1px; } }

.about-type-small {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.005em; }
  @media (min-width: 1024px) {
    .about-type-small {
      font-size: 19px;
      line-height: 24px;
      letter-spacing: -0.005em; } }

.about-type-big {
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.015em; }
  @media (min-width: 750px) {
    .about-type-big {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.005em; } }

.credits-type-big {
  font-size: 41px;
  line-height: 50px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .credits-type-big {
      font-size: 35px;
      line-height: 42px; } }
  @media (min-width: 750px) {
    .credits-type-big {
      font-size: 68px;
      line-height: 82px; } }

.credits-type {
  font-size: 26px;
  line-height: 32px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .credits-type {
      font-size: 20px;
      line-height: 26px; } }
  @media (min-width: 750px) {
    .credits-type {
      font-size: 40px;
      line-height: 50px; } }

.overlay-type {
  font-size: 26px;
  line-height: 33px;
  letter-spacing: 0.015em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .overlay-type {
      font-size: 20px;
      line-height: 26px; } }
  @media (min-width: 750px) {
    .overlay-type {
      font-size: 29px;
      line-height: 38px; } }

.overlay-button {
  font-size: 29px;
  line-height: 40px;
  letter-spacing: 0.03em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .overlay-button {
      font-size: 23px;
      line-height: 29px; } }
  @media (min-width: 750px) {
    .overlay-button {
      font-size: 31px;
      line-height: 40px; } }

.debug-type {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 10px;
  line-height: 20px; }

a,
a:hover,
a:visited,
a:link {
  color: black;
  pointer-events: all !important;
  text-decoration: none;
  box-shadow: 0 0.06em 0 0.0001em black; }
  .safari a, .safari
  a:hover, .safari
  a:visited, .safari
  a:link {
    box-shadow: 0 0.08em 0 0.0001em black; }

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
.evenly-like {
  display: flex;
  justify-content: space-between; }
  .evenly-like:before, .evenly-like:after {
    content: '';
    display: block; }

.Hint {
  box-sizing: border-box;
  position: absolute;
  padding: 7px 0 0 13px;
  height: 120px;
  width: 100%;
  text-align: left;
  transform: translate3d(0, -120px, 0);
  pointer-events: none !important;
  transition: transform 400ms ease-out;
  color: var(--ui-color);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4428) 19%, rgba(0, 0, 0, 0.3246) 34%, rgba(0, 0, 0, 0.2292) 47%, rgba(0, 0, 0, 0.1668) 56.5%, rgba(0, 0, 0, 0.1164) 65%, rgba(0, 0, 0, 0.0756) 73%, rgba(0, 0, 0, 0.045) 80.2%, rgba(0, 0, 0, 0.0252) 86.1%, rgba(0, 0, 0, 0.0126) 91%, rgba(0, 0, 0, 0.0048) 95.2%, rgba(0, 0, 0, 0.0012) 98.2%, rgba(0, 0, 0, 0) 100%); }
  .Hint.visible {
    transform: translate3d(0, 0, 0); }
    .Hint.visible.TapHint:after {
      content: '→';
      position: absolute;
      margin-left: 25px;
      animation: bounce 2s infinite; }
    .mobile.landscape .Hint.visible {
      text-align: center;
      padding-left: 37px; }
      .mobile.landscape .Hint.visible.TapHint:after {
        content: none; }
      .mobile.landscape .Hint.visible.TapHint:before {
        content: '→';
        position: absolute;
        margin-left: -52px;
        animation: bounce 2s infinite; }
  .Hint .MuteButton {
    margin-left: 25px;
    position: relative;
    top: 1px; }
    .Hint .MuteButton svg {
      height: 20px;
      width: auto; }
    .Hint .MuteButton .color-fill {
      fill: var(--ui-color); }
    .Hint .MuteButton .color-stroke {
      stroke: var(--ui-color); }
  @media (min-width: 750px) {
    .Hint {
      padding: 17px 0 0 23px; } }

.DesktopHint {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding-top: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.1845) 19%, rgba(0, 0, 0, 0.13525) 34%, rgba(0, 0, 0, 0.0955) 47%, rgba(0, 0, 0, 0.0695) 56.5%, rgba(0, 0, 0, 0.0485) 65%, rgba(0, 0, 0, 0.0315) 73%, rgba(0, 0, 0, 0.01875) 80.2%, rgba(0, 0, 0, 0.0105) 86.1%, rgba(0, 0, 0, 0.00525) 91%, rgba(0, 0, 0, 0.002) 95.2%, rgba(0, 0, 0, 0.0005) 98.2%, rgba(0, 0, 0, 0) 100%);
  z-index: 9;
  font-size: 28px;
  letter-spacing: -0.01em;
  color: var(--ui-color);
  transform: translate3d(0, -120px, 0);
  pointer-events: none !important;
  transition: transform 400ms ease-out; }
  .DesktopHint.visible {
    transform: translate3d(0, 0, 0); }

@keyframes bounce {
  0% {
    transform: rotate(90deg) translateX(0); }
  20% {
    transform: rotate(90deg) translateX(0); }
  50% {
    transform: rotate(90deg) translateX(0); }
  80% {
    transform: rotate(90deg) translateX(0); }
  40% {
    transform: rotate(90deg) translateX(-20px); }
  60% {
    transform: rotate(90deg) translateX(-10px); }
  100% {
    transform: rotate(90deg) translateX(0); } }
