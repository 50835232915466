@import '_variables';

.loading-type {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -(10em / 1000);

  .mobile.landscape & {
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -(20em / 1000);
  }

  @include small() {
    font-size: 42px;
    line-height: 55px;

    .mobile.landscape & {
      font-size: 42px;
      line-height: 55px;
    }
  }

  @include tablet() {
    font-size: 80px;
    line-height: 90px;
    letter-spacing: -(20em / 1000);
  }
}

.subtitle-type {
  font-size: 22px;
  line-height: 30px;
}

.welcome-type {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -(20em / 1000);

  &.long-title {
    font-size: 51px;
    line-height: 54px;
  }

  @include small() {
    font-size: 50px;
    line-height: 55px;
  }

  @include tablet() {
    font-size: 90px;
    line-height: 95px;
  }
}

.enter-button-type {
  font-size: 55px;
  line-height: 65px;
  letter-spacing: (25em / 1000);
  text-transform: uppercase;
  border-bottom: 3px solid var(--ui-color);

  @include small() {
    font-size: 47px;
    line-height: 52px;
  }

  @include tablet() {
    font-size: 80px;
    line-height: 90px;
    letter-spacing: (25em / 1000);
    border-bottom: 5px solid var(--ui-color);
  }
}

.title-seq-type {
  font-size: 63px;
  line-height: 65px;
  letter-spacing: -(20em / 1000);

  .mobile.landscape & {
    font-size: 77px;
    line-height: 76px;
    letter-spacing: -(10em / 1000);
  }

  @include small() {
    font-size: 57px;
    line-height: 60px;
    .mobile.landscape & {
      font-size: 57px;
      line-height: 60px;
    }
  }

  @include tablet() {
    font-size: 108px;
    line-height: 112px;
    letter-spacing: -(15em / 1000);
  }
}

.bottom-box-type {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: (5em / 1000);

  @include small() {
    font-size: 18px;
    line-height: 22px;
  }

  @include tablet() {
    font-size: 29px;
    line-height: 27px;
    letter-spacing: 0;
  }
}

.bottom-box-type.no-bg {
  @extend .bottom-box-type;
  line-height: 30px;

  @include small() {
    line-height: 26px;
  }
}

.hint-type {
  font-size: 28px;

  .mobile.landscape & {
    font-size: 30px;
  }

  @include small() {
    font-size: 25px;
    line-height: 33px;
  }

  @include tablet() {
    font-size: 33px;
  }
}

.instruction-type {
  font-size: 27px;
  line-height: 32px;
  letter-spacing: (15em / 1000);

  @include small() {
    font-size: 24px;
    line-height: 29px;
  }

  @include tablet() {
    font-size: 30px;
    line-height: 34px;
  }
}

.quote-type {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: (20em / 1000);

  @include small() {
    font-size: 16px;
    line-height: 20px;
  }

  @include tablet() {
    font-size: 20px;
    line-height: 26px;
  }
}

.confirmation-type {
  font-size: 29px;
  letter-spacing: (30em / 1000);
  text-transform: uppercase;

  @include small() {
    font-size: 25px;
  }

  @include tablet() {
    font-size: 31px;
    line-height: 40px;
  }
}

.language-type {
  font-size: 23px;
  line-height: 1em;
  padding-bottom: 2px;
  text-transform: uppercase;

  @include small() {
    font-size: 18px;
    line-height: 22px;
  }

  @include tablet() {
    font-size: 29px;
    padding-bottom: 1px;
    line-height: 0.9em;
  }

  @include desktop() {
    font-size: 22px;
    padding-bottom: 0;
    padding-top: 1px;
  }
}

.about-type-small {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -(5em / 1000);

  @include desktop() {
    font-size: 19px;
    line-height: 24px;
    letter-spacing: -(5em / 1000);
  }
}

.about-type-big {
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -(15em / 1000);

  @include tablet() {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -(5em / 1000);
  }
}

.credits-type-big {
  font-size: 41px;
  line-height: 50px;

  @include small() {
    font-size: 35px;
    line-height: 42px;
  }

  @include tablet() {
    font-size: 68px;
    line-height: 82px;
  }
}

.credits-type {
  font-size: 26px;
  line-height: 32px;

  @include small() {
    font-size: 20px;
    line-height: 26px;
  }

  @include tablet() {
    font-size: 40px;
    line-height: 50px;
  }
}

.overlay-type {
  font-size: 26px;
  line-height: 33px;
  letter-spacing: (15em / 1000);

  @include small() {
    font-size: 20px;
    line-height: 26px;
  }

  @include tablet() {
    font-size: 29px;
    line-height: 38px;
  }
}

.overlay-button {
  font-size: 29px;
  line-height: 40px;
  letter-spacing: (30em / 1000);

  @include small() {
    font-size: 23px;
    line-height: 29px;
  }

  @include tablet() {
    font-size: 31px;
    line-height: 40px;
  }
}

.debug-type {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 10px;
  line-height: 20px;
}

a,
a:hover,
a:visited,
a:link {
  color: black;
  pointer-events: all !important;
  text-decoration: none;
  box-shadow: 0 0.06em 0 0.0001em black;

  .safari & {
    box-shadow: 0 0.08em 0 0.0001em black;
  }
}
