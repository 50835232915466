@charset "UTF-8";
/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
.evenly-like, .EndCredits .again-page {
  display: flex;
  justify-content: space-between; }
  .evenly-like:before, .EndCredits .again-page:before, .evenly-like:after, .EndCredits .again-page:after {
    content: '';
    display: block; }

.loading-type {
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.01em; }
  .mobile.landscape .loading-type {
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.02em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .loading-type {
      font-size: 42px;
      line-height: 55px; }
      .mobile.landscape .loading-type {
        font-size: 42px;
        line-height: 55px; } }
  @media (min-width: 750px) {
    .loading-type {
      font-size: 80px;
      line-height: 90px;
      letter-spacing: -0.02em; } }

.subtitle-type {
  font-size: 22px;
  line-height: 30px; }

.welcome-type {
  font-size: 60px;
  line-height: 60px;
  letter-spacing: -0.02em; }
  .welcome-type.long-title {
    font-size: 51px;
    line-height: 54px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .welcome-type {
      font-size: 50px;
      line-height: 55px; } }
  @media (min-width: 750px) {
    .welcome-type {
      font-size: 90px;
      line-height: 95px; } }

.enter-button-type {
  font-size: 55px;
  line-height: 65px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  border-bottom: 3px solid var(--ui-color); }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .enter-button-type {
      font-size: 47px;
      line-height: 52px; } }
  @media (min-width: 750px) {
    .enter-button-type {
      font-size: 80px;
      line-height: 90px;
      letter-spacing: 0.025em;
      border-bottom: 5px solid var(--ui-color); } }

.title-seq-type {
  font-size: 63px;
  line-height: 65px;
  letter-spacing: -0.02em; }
  .mobile.landscape .title-seq-type {
    font-size: 77px;
    line-height: 76px;
    letter-spacing: -0.01em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .title-seq-type {
      font-size: 57px;
      line-height: 60px; }
      .mobile.landscape .title-seq-type {
        font-size: 57px;
        line-height: 60px; } }
  @media (min-width: 750px) {
    .title-seq-type {
      font-size: 108px;
      line-height: 112px;
      letter-spacing: -0.015em; } }

.bottom-box-type, .bottom-box-type.no-bg {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.005em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .bottom-box-type, .bottom-box-type.no-bg {
      font-size: 18px;
      line-height: 22px; } }
  @media (min-width: 750px) {
    .bottom-box-type, .bottom-box-type.no-bg {
      font-size: 29px;
      line-height: 27px;
      letter-spacing: 0; } }

.no-bg.bottom-box-type {
  line-height: 30px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .no-bg.bottom-box-type {
      line-height: 26px; } }

.hint-type {
  font-size: 28px; }
  .mobile.landscape .hint-type {
    font-size: 30px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .hint-type {
      font-size: 25px;
      line-height: 33px; } }
  @media (min-width: 750px) {
    .hint-type {
      font-size: 33px; } }

.instruction-type {
  font-size: 27px;
  line-height: 32px;
  letter-spacing: 0.015em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .instruction-type {
      font-size: 24px;
      line-height: 29px; } }
  @media (min-width: 750px) {
    .instruction-type {
      font-size: 30px;
      line-height: 34px; } }

.quote-type {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .quote-type {
      font-size: 16px;
      line-height: 20px; } }
  @media (min-width: 750px) {
    .quote-type {
      font-size: 20px;
      line-height: 26px; } }

.confirmation-type {
  font-size: 29px;
  letter-spacing: 0.03em;
  text-transform: uppercase; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .confirmation-type {
      font-size: 25px; } }
  @media (min-width: 750px) {
    .confirmation-type {
      font-size: 31px;
      line-height: 40px; } }

.language-type {
  font-size: 23px;
  line-height: 1em;
  padding-bottom: 2px;
  text-transform: uppercase; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .language-type {
      font-size: 18px;
      line-height: 22px; } }
  @media (min-width: 750px) {
    .language-type {
      font-size: 29px;
      padding-bottom: 1px;
      line-height: 0.9em; } }
  @media (min-width: 1024px) {
    .language-type {
      font-size: 22px;
      padding-bottom: 0;
      padding-top: 1px; } }

.about-type-small {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.005em; }
  @media (min-width: 1024px) {
    .about-type-small {
      font-size: 19px;
      line-height: 24px;
      letter-spacing: -0.005em; } }

.about-type-big {
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.015em; }
  @media (min-width: 750px) {
    .about-type-big {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: -0.005em; } }

.credits-type-big, .EndCredits .credits-big p {
  font-size: 41px;
  line-height: 50px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .credits-type-big, .EndCredits .credits-big p {
      font-size: 35px;
      line-height: 42px; } }
  @media (min-width: 750px) {
    .credits-type-big, .EndCredits .credits-big p {
      font-size: 68px;
      line-height: 82px; } }

.credits-type, .EndCredits p,
.EndCredits li, .EndCredits .again-page {
  font-size: 26px;
  line-height: 32px; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .credits-type, .EndCredits p,
    .EndCredits li, .EndCredits .again-page {
      font-size: 20px;
      line-height: 26px; } }
  @media (min-width: 750px) {
    .credits-type, .EndCredits p,
    .EndCredits li, .EndCredits .again-page {
      font-size: 40px;
      line-height: 50px; } }

.overlay-type {
  font-size: 26px;
  line-height: 33px;
  letter-spacing: 0.015em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .overlay-type {
      font-size: 20px;
      line-height: 26px; } }
  @media (min-width: 750px) {
    .overlay-type {
      font-size: 29px;
      line-height: 38px; } }

.overlay-button {
  font-size: 29px;
  line-height: 40px;
  letter-spacing: 0.03em; }
  @media (orientation: portrait) and (max-width: 349px), (orientation: landscape) and (max-width: 568px) {
    .overlay-button {
      font-size: 23px;
      line-height: 29px; } }
  @media (min-width: 750px) {
    .overlay-button {
      font-size: 31px;
      line-height: 40px; } }

.debug-type {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace !important;
  font-size: 10px;
  line-height: 20px; }

a,
a:hover,
a:visited,
a:link {
  color: black;
  pointer-events: all !important;
  text-decoration: none;
  box-shadow: 0 0.06em 0 0.0001em black; }
  .safari a, .safari
  a:hover, .safari
  a:visited, .safari
  a:link {
    box-shadow: 0 0.08em 0 0.0001em black; }

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/
.evenly-like, .EndCredits .again-page {
  display: flex;
  justify-content: space-between; }
  .evenly-like:before, .EndCredits .again-page:before, .evenly-like:after, .EndCredits .again-page:after {
    content: '';
    display: block; }

.EndCredits {
  backface-visibility: hidden;
  perspective: 1000;
  transform: translateZ(0);
  overflow: scroll;
  width: 100%; }
  .EndCredits .container .gradient {
    margin-top: 100vh;
    height: 100vh;
    margin-top: calc(var(--vh, 1vh) * 100);
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.2583) 19%, rgba(0, 0, 0, 0.18935) 34%, rgba(0, 0, 0, 0.1337) 47%, rgba(0, 0, 0, 0.0973) 56.5%, rgba(0, 0, 0, 0.0679) 65%, rgba(0, 0, 0, 0.0441) 73%, rgba(0, 0, 0, 0.02625) 80.2%, rgba(0, 0, 0, 0.0147) 86.1%, rgba(0, 0, 0, 0.00735) 91%, rgba(0, 0, 0, 0.0028) 95.2%, rgba(0, 0, 0, 0.0007) 98.2%, rgba(0, 0, 0, 0) 100%); }
  .EndCredits .container .background {
    width: 100%;
    background: rgba(0, 0, 0, 0.35); }
  .EndCredits .centered {
    max-width: 400px;
    margin: 0 auto; }
  .EndCredits .credits-big p {
    margin-bottom: 66vh; }
  .EndCredits .credits-page {
    margin-bottom: 66vh; }
  .EndCredits ul {
    margin-bottom: 2em;
    list-style: none;
    padding: 0; }
  .EndCredits p,
  .EndCredits li {
    padding: 0 30px;
    color: var(--ui-color); }
  .EndCredits .again-page {
    height: calc(100vh - 80px);
    height: calc((var(--vh, 1vh) * 100) - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--ui-color); }
    .EndCredits .again-page.installation {
      font-size: 6em; }
  .EndCredits a {
    color: var(--ui-color) !important;
    box-shadow: 0 0.06em 0 0.0001em var(--ui-color) !important; }
    .safari .EndCredits a {
      box-shadow: 0 0.08em 0 0.0001em var(--ui-color) !important; }
  .mobile.landscape .EndCredits .centered {
    max-width: 600px; }
  .mobile.landscape .EndCredits .again-page {
    justify-content: center;
    height: calc(100vh - 54px);
    height: calc((var(--vh, 1vh) * 100) - 54px); }
    .mobile.landscape .EndCredits .again-page p {
      padding: 0;
      margin: 0px -50px 20px -50px; }
      .mobile.landscape .EndCredits .again-page p:last-child {
        margin-bottom: 0; }
      .mobile.landscape .EndCredits .again-page p br:first-of-type {
        content: ' '; }
        .mobile.landscape .EndCredits .again-page p br:first-of-type:after {
          content: ' '; }
      .mobile.landscape .EndCredits .again-page p br:last-of-type {
        content: ' '; }
        .mobile.landscape .EndCredits .again-page p br:last-of-type:after {
          content: ' → '; }
  @media (min-width: 750px) {
    .EndCredits .centered {
      max-width: 600px; } }
  .desktop .EndCredits .again-page {
    height: calc(100vh); }
