@import '../../_typography';
@import '../../_variables';

.Start {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zUi;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--ui-color);
  transform: translateZ(0);
  perspective: 1000;

  &.loaded {
    display: block;
    background: none;
  }

  &.error {
    background: none;
  }
}
