@import '../../_typography';
@import '../../_variables';

.TitleSequence {
  @extend .title-seq-type;
  color: var(--ui-color);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0.2em 0;

  &.visible {
    background: rgba(0, 0, 0, 0.35);
    transition: background 0ms ease-out;
  }

  &.hidden {
    background: rgba(0, 0, 0, 0);
    transition: background 1000ms ease-out;
  }
}
