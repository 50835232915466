@import '../../_typography';

.IntroTextAnimation {
  @extend .loading-type;

  padding-bottom: 0.5em;

  .mobile.landscape & {
    padding-bottom: 0;
  }
}
