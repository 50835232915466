@import '../../_variables';

.CameraButtons {
  position: absolute;
  top: 50%;
  transform: translateY(-50px);
  right: 0;
  padding-right: 8px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .shadow {
    position: absolute;
    z-index: -1;
    top: -130px;
    left: -105px;
    right: 0;
    bottom: -110px;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 90px;
      left: 90px;
      bottom: 100px;
      right: -100px;
      background: rgba(0, 0, 0, 0.3);
      filter: blur(40px);
    }
  }

  @include small() {
    height: 103px;
  }

  @include tablet() {
    right: 26px;
  }
}
