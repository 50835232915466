@import '../../_variables';

.Viewer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zViewer;
  overflow: hidden;
}
