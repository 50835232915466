@import '../../_typography';
@import '../../_variables';

.QuotesContainer {
  @extend .quote-type;
  color: var(--ui-color);

  position: absolute;
  padding-bottom: 12px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  &:before {
    pointer-events: none;
    z-index: -1;
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    @include scrimGradient(rgba(0, 0, 0, 0.2), 'to top');
  }

  @include tablet() {
    padding-bottom: 20px;
  }
}
