@import '../../_typography';
@import '../../_variables';

.Timeline {
  pointer-events: all;
  transition: opacity 500ms;
  opacity: 1;

  &.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .progress-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;

    &:before {
      pointer-events: none;
      content: '';
      position: absolute;
      top: -20px;
      left: 0;
      right: 0;
      bottom: 0;
      @include scrimGradient(rgba(0, 0, 0, 0.15), 'to top');
    }

    .progress {
      position: absolute;
      bottom: 7px;
      height: 5px;
      background: var(--ui-color);
    }
  }

  @include tablet() {
    .progress-container {
      height: 40px;

      .progress {
        bottom: 9px;
        height: 6px;
      }
    }
  }
}
